<template>
    
<svg width="182" height="161" viewBox="0 0 182 161" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M132.773 68.0735L132.015 64.5963C131.859 63.8905 131.32 63.3923 130.697 63.3923H122.144L118.584 55.483C117.131 52.2445 113.902 50.1582 110.353 50.1582H71.6261C68.0762 50.1582 64.8586 52.2445 63.395 55.483L59.8348 63.3923H51.2819C50.6592 63.3923 50.1194 63.8905 49.9637 64.5963L49.206 68.0735C49.0088 68.9973 49.5278 69.9211 50.327 70.0664L55.3611 71.2601L53.5136 73.2737C52.4341 74.1768 51.8009 75.5054 51.8009 76.917V108.866C51.8009 110.07 52.7766 111.056 53.991 111.056H61.4955C62.6996 111.056 63.6856 110.08 63.6856 108.866V101.133H118.283V108.866C118.283 110.07 119.258 111.056 120.473 111.056H127.977C129.181 111.056 130.167 110.08 130.167 108.866V76.917C130.167 75.5054 129.545 74.1768 128.455 73.2737L126.607 71.2601L131.641 70.0664C132.441 69.9315 132.96 68.9973 132.762 68.0735H132.773ZM69.519 87.4108C69.3426 87.5042 69.1557 87.5562 68.9585 87.5458L56.4925 87.1098C55.5376 87.0787 54.7695 86.3106 54.7384 85.3557L54.6242 81.92C54.5931 80.9132 55.3819 80.0724 56.3784 80.0413L59.6895 79.9375C59.949 79.9375 60.1981 79.979 60.4368 80.0724L70.7023 83.4147C71.1798 83.6119 69.519 87.4212 69.519 87.4212V87.4108ZM60.0216 70.6996L65.8135 56.5417C66.8411 54.2582 69.135 52.7843 71.6365 52.7843H110.363C112.875 52.7843 115.158 54.2582 116.186 56.5417L121.978 70.6996C112.211 70.0353 101.26 69.9522 91.0049 69.9522C80.7498 69.9522 69.7993 70.0353 60.032 70.6996H60.0216ZM127.365 81.9096L127.251 85.3453C127.22 86.3002 126.452 87.0683 125.497 87.0994L113.031 87.5354C112.833 87.5354 112.636 87.4939 112.47 87.4005C112.47 87.4005 110.809 83.5911 111.287 83.3939L121.552 80.0517C121.791 79.9582 122.04 79.9063 122.3 79.9167L125.611 80.0205C126.618 80.0517 127.406 80.8924 127.365 81.8992V81.9096Z" fill="#0A66A8"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M165.253 99.7498C168.803 99.7498 172.208 98.3395 174.718 95.8292C177.228 93.3189 178.638 89.9142 178.638 86.3641C178.638 82.814 177.228 79.4094 174.718 76.8991C172.208 74.3888 168.803 72.9785 165.253 72.9785C161.703 72.9785 158.298 74.3888 155.788 76.8991C153.277 79.4094 151.867 82.814 151.867 86.3641C151.867 89.9142 153.277 93.3189 155.788 95.8292C158.298 98.3395 161.703 99.7498 165.253 99.7498ZM171.455 85.1812L166.436 80.1616C166.12 79.8568 165.698 79.6881 165.259 79.692C164.82 79.6958 164.4 79.8717 164.09 80.182C163.78 80.4922 163.604 80.9118 163.6 81.3506C163.596 81.7893 163.765 82.2119 164.07 82.5275L166.233 84.6909H160.233C159.789 84.6909 159.364 84.8672 159.05 85.181C158.736 85.4948 158.56 85.9204 158.56 86.3641C158.56 86.8079 158.736 87.2335 159.05 87.5473C159.364 87.8611 159.789 88.0373 160.233 88.0373H166.233L164.07 90.2008C163.91 90.3551 163.783 90.5398 163.695 90.7439C163.607 90.948 163.561 91.1676 163.559 91.3898C163.557 91.6119 163.6 91.8323 163.684 92.0379C163.768 92.2435 163.892 92.4303 164.049 92.5874C164.206 92.7445 164.393 92.8688 164.599 92.9529C164.804 93.037 165.025 93.0794 165.247 93.0774C165.469 93.0755 165.689 93.0294 165.893 92.9417C166.097 92.854 166.281 92.7265 166.436 92.5667L171.455 87.5471C171.769 87.2333 171.945 86.8078 171.945 86.3641C171.945 85.9205 171.769 85.495 171.455 85.1812Z" fill="#374151"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M91.0028 157.265C94.5529 157.265 97.9576 155.855 100.468 153.345C102.978 150.835 104.388 147.43 104.388 143.88C104.388 140.33 102.978 136.925 100.468 134.415C97.9576 131.904 94.5529 130.494 91.0028 130.494C87.4527 130.494 84.048 131.904 81.5377 134.415C79.0275 136.925 77.6172 140.33 77.6172 143.88C77.6172 147.43 79.0275 150.835 81.5377 153.345C84.048 155.855 87.4527 157.265 91.0028 157.265ZM92.676 138.86C92.676 138.416 92.4997 137.991 92.1859 137.677C91.8722 137.363 91.4466 137.187 91.0028 137.187C90.559 137.187 90.1335 137.363 89.8197 137.677C89.5059 137.991 89.3296 138.416 89.3296 138.86V144.86L87.1662 142.697C86.8506 142.392 86.4279 142.223 85.9892 142.227C85.5505 142.231 85.1309 142.407 84.8206 142.717C84.5104 143.027 84.3344 143.447 84.3306 143.886C84.3268 144.324 84.4955 144.747 84.8002 145.063L89.8199 150.082C90.1336 150.396 90.5591 150.572 91.0028 150.572C91.4465 150.572 91.872 150.396 92.1858 150.082L97.2054 145.063C97.5102 144.747 97.6788 144.324 97.675 143.886C97.6712 143.447 97.4952 143.027 97.185 142.717C96.8748 142.407 96.4551 142.231 96.0164 142.227C95.5777 142.223 95.155 142.392 94.8395 142.697L92.676 144.86V138.86Z" fill="#374151"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M16.7528 99.7498C20.3029 99.7498 23.7076 98.3395 26.2179 95.8292C28.7282 93.3189 30.1384 89.9142 30.1384 86.3641C30.1384 82.814 28.7282 79.4094 26.2179 76.8991C23.7076 74.3888 20.3029 72.9785 16.7528 72.9785C13.2027 72.9785 9.79804 74.3888 7.28775 76.8991C4.77745 79.4094 3.36719 82.814 3.36719 86.3641C3.36719 89.9142 4.77745 93.3189 7.28775 95.8292C9.79804 98.3395 13.2027 99.7498 16.7528 99.7498ZM17.9358 82.5275C18.2406 82.2119 18.4092 81.7893 18.4054 81.3506C18.4016 80.9118 18.2256 80.4922 17.9154 80.182C17.6052 79.8717 17.1855 79.6958 16.7468 79.692C16.3081 79.6881 15.8854 79.8568 15.5699 80.1616L10.5502 85.1812C10.2366 85.495 10.0604 85.9205 10.0604 86.3641C10.0604 86.8078 10.2366 87.2333 10.5502 87.5471L15.5699 92.5667C15.8854 92.8715 16.3081 93.0401 16.7468 93.0363C17.1855 93.0325 17.6052 92.8565 17.9154 92.5463C18.2256 92.2361 18.4016 91.8164 18.4054 91.3777C18.4092 90.939 18.2406 90.5164 17.9358 90.2008L15.7723 88.0373H21.7724C22.2162 88.0373 22.6418 87.8611 22.9556 87.5473C23.2693 87.2335 23.4456 86.8079 23.4456 86.3641C23.4456 85.9204 23.2693 85.4948 22.9556 85.181C22.6418 84.8672 22.2162 84.6909 21.7724 84.6909H15.7723L17.9358 82.5275Z" fill="#374151"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M91.0028 30.7302C94.5529 30.7302 97.9576 29.32 100.468 26.8097C102.978 24.2994 104.388 20.8947 104.388 17.3446C104.388 13.7945 102.978 10.3898 100.468 7.87954C97.9576 5.36925 94.5529 3.95898 91.0028 3.95898C87.4527 3.95898 84.048 5.36925 81.5377 7.87954C79.0275 10.3898 77.6172 13.7945 77.6172 17.3446C77.6172 20.8947 79.0275 24.2994 81.5377 26.8097C84.048 29.32 87.4527 30.7302 91.0028 30.7302ZM97.2054 16.1617L92.1858 11.142C91.872 10.8284 91.4465 10.6522 91.0028 10.6522C90.5591 10.6522 90.1336 10.8284 89.8199 11.142L84.8002 16.1617C84.4955 16.4772 84.3268 16.8999 84.3306 17.3386C84.3344 17.7773 84.5104 18.197 84.8206 18.5072C85.1309 18.8174 85.5505 18.9934 85.9892 18.9972C86.4279 19.001 86.8506 18.8323 87.1662 18.5276L89.3296 16.3641V22.3642C89.3296 22.808 89.5059 23.2336 89.8197 23.5473C90.1335 23.8611 90.559 24.0374 91.0028 24.0374C91.4466 24.0374 91.8722 23.8611 92.1859 23.5473C92.4997 23.2336 92.676 22.808 92.676 22.3642V16.3641L94.8395 18.5276C95.155 18.8323 95.5777 19.001 96.0164 18.9972C96.4551 18.9934 96.8748 18.8174 97.185 18.5072C97.4952 18.197 97.6712 17.7773 97.675 17.3386C97.6788 16.8999 97.5102 16.4772 97.2054 16.1617Z" fill="#374151"/>
</svg>

</template>
import { md5 } from "js-md5";
import { useRoute } from "vue-router";

export const useDealerContact = () => {
  const backend = useBackend();
  const route = useRoute();
  const { analyticsLayer } = useAnalytics();

  const submit = (formData) => {
    return new Promise(async (resolve) => {
      const data = {
        mail: formData.value.mail,
        name: formData.value.name,
        phone: formData.value.phone,
        dealer: formData.value.dealer,
        job: formData.value.job,
        agr1: formData.value.agr_p ? 1 : 0,
        agr2: formData.value.agr_m ? 1 : 0,
      };

      const result = await backend.sendDealerContactForm(data);

      if (!result.success) {
        resolve(false);
        return;
      }
      let transactionId = result.data.request_id;

      analyticsLayer({
        event: "generate_lead",
        form_type: "dla_dealera",
        transactionId: transactionId,
        email: data.mail,
        md5_email_hash: data.mail ? md5(data.mail.toLowerCase().trim()) : "",
        phone: data.phone,
      });

      resolve(true);
    });
  };

  return {
    submit,
  };
};

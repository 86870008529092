<script setup>

</script>

<template>
    <div>
    <SubContentHeader>Jak działa VASH?</SubContentHeader>
    <ContentDescription :textCenter="false" textLeft>Dzięki zastosowaniu sztucznej inteligencji, proces integracji z vehis.pl  jest prosty i szybki:</ContentDescription>
    <ContentSteps :negMargin="false">
        <ContentStep>Udostępnij nam w dowolnej formie swój <b>stock samochodów nowych i używanych.</b></ContentStep>
        <ContentStep><b>Sztuczna inteligencja rozkoduje informacje</b> o samochodach i <b>opublikuje oferty</b> na vehis.pl.</ContentStep>
        <ContentStep selected><b>Nasi Klienci zobaczą Twoją aktualną ofertą samochodów</b> zaraz po imporcie, 24h/7.</ContentStep>
    </ContentSteps>
</div>
</template>

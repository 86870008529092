<script setup>
// todo: disclaimer link

import texts from "~/configs/texts";

const contact = useDealerContact();

const props = defineProps({
  title: String,
  standalone: Boolean,
  docking: Boolean,
  car: Object,
  noContainer: Boolean,
  fluid: Boolean,
  source: String,
  additionalData: {
    type: Object,
    default: null
  },
  btnCenter: {
    type: Boolean,
    default: () => false
  },
});

const mailExpand = ref(false);
const phoneExpand = ref(false);
const refStateBlend = ref();

const defaultData = {
  name: null,
  phone: null,
  mail: null,
  dealer: null,
  job: null,
  agr_m: false,
  agr_p: false,
  brand: null,
  model: null,
  body: null,
  product_id: null
}

const formData = ref({...defaultData});
const errors = ref({...defaultData})

const clearForm = () => {
  formData.value = {...defaultData};
  errors.value = {...defaultData};
}

const validateAndSend = async () => {
  errors.value = {...defaultData}
  let passed = true;
  if (!formData.value.name || formData.value.name.length < 2) {
    errors.value.name = "Ta wartość jest niepoprawna";
    passed = false;
  }
  if (!formData.value.phone || !validatePhone(formData.value.phone)) {
    errors.value.phone = "Wprowadź poprawny numer telefonu";
    passed = false;
  }

  if (!formData.value.mail || !validateEmail(formData.value.mail)) {
    errors.value.mail = "Wprowadź poprawny adres email";
    passed = false;
  }
  if (!formData.value.dealer) {
    errors.value.mail = "Wprowadź poprawną nazwę dealera";
    passed = false;
  }
  if (!formData.value.job ) {
    errors.value.mail = "Wprowadź poprawną nazwę stanowiska";
    passed = false;
  }

  if (!formData.value.agr_p) {
    errors.value.agr_p = "To pole jest wymagane";
    passed = false;
  }

  if (!formData.value.agr_m) {
    errors.value.agr_m = "To pole jest wymagane";
    passed = false;
  }

  if(props.car) {
    formData.value.brand = props.car.brandName;
    formData.value.model = props.car.modelName;
    formData.value.body = props.car.bodyType;
    formData.value.product_id = props.car.group_id;
  }

  if(props.additionalData) {
    formData.value.options = props.additionalData;
  }

  if(props.source) {
    formData.value.source = props.source;
  }

  if(passed) {
    refStateBlend.value.setBusy();

    const result = await contact.submit(formData)
    if(!result) {
      refStateBlend.value.setFailure();
    } else {
      refStateBlend.value.setSuccess();
      clearForm();
    }
  }
}

const opened = ref(false);

onMounted(() => {
  if(props.docking) {
    document.documentElement.classList.add('dock');
  }
})

onUnmounted(() => {
  if(props.docking) {
    document.documentElement.classList.remove('dock');
  }
})

</script>

<template>
  <div :class="{
      'rounded-lg': !docking && !noContainer,
      'overflow-hidden shadow-box bg-white': !noContainer,
      'lg:sticky lg:top-48': !standalone,
      'lg:max-w-[948px] lg:mx-auto': standalone,
      'dock fixed left-0 w-full z-60 lg:relative lg:z-0 rounded-0 lg:rounded-lg': docking}
      ">
    <div v-if='docking && !opened' :class="['block lg:hidden bg-white container mx-auto pt-2 pb-4']">
      <div 
        class="flex flex-wrap w-full items-center px-4 sm:px-8 gap-y-1"
        :class="btnCenter ?  'justify-center': 'justify-between'">
        <div class="font-extralight text-sm w-1/2 md:w-1/3" v-if="props.car">
          <div class="whitespace-nowrap overflow-ellipsis overflow-hidden">{{ props.car?.brandName }} {{ props.car?.modelName }}</div>
          <div class="whitespace-nowrap overflow-ellipsis overflow-hidden">{{ props.car?.versionName }}</div>
        </div>
        <div class="flex gap-2 w-2/7" v-if="props.car?.installment">
          <div>
            <EffectPrice class="text-azure text-4xl" :value="props.car?.installment" />
          </div>
          <div class="font-extralight text-xs pt-1">
            <div>zł
              <EffectNetGrossIndicator />
            </div>
            <div>miesięcznie</div>
          </div>
        </div>
        <div :class="['w-full md:w-1/3 pt-0 sm:pt-1', {'flex items-center justify-center':!props.car}]">
          <ButtonRounded color="azure"
                         @click="opened = true"
                         :class="{'!w-52':!props.car}">{{ texts.form.title.line_2 }}
          </ButtonRounded>
        </div>
      </div>
    </div>
    <div class="relative">
      <FormStateBlend ref="refStateBlend" />
      <div :class="['px-6 py-3',{'container mx-auto':docking, 'hidden lg:block':docking && !opened, 'block !z-60': docking && opened, '!py-6': !noContainer}]">

        <div class="mb-4 relative">
          <div v-if="docking" class="flex lg:hidden w-10 h-10 justify-center items-center rounded bg-gray-200 absolute right-0 top-0 cursor-pointer transition-opacity z-60"
               @click="opened = false">
            <IconClose class="!w-5 !h-5 stroke-1" />
          </div>
          <template v-if="props.title">
            <div class="px-4 md:px-20 lg:px-4 text-xl flex flex-col md:flex-row md:gap-1 justify-center items-center">
              <div v-html="props.title" class="text-center"></div>
            </div>
          </template>
          <template v-else>
            <div class="text-xl flex flex-col md:flex-row md:gap-1 justify-center items-center"
                 :class="{'lg:flex-col lg:gap-0':!standalone}"
            >
              <div>{{ texts.form.title.line_1 }}</div>
              <div class="text-azure">{{ texts.form.title.line_2 }}</div>
            </div>
          </template>
        </div>
        <form novalidate>
          <div class="flex flex-col gap-3 md:flex-row md:gap-6"
               :class="{'lg:flex-col lg:gap-3': !standalone && !fluid, 'md:flex-col gap-6 xl:flex-row xl:gap-3': fluid}">
            <InputText label="Imię" v-model="formData.name" :error="errors.name" />
            <InputText label="Numer telefonu" type="tel" v-model="formData.phone" :error="errors.phone" />
            <InputText label="E-mail" type="email" v-model="formData.mail" :error="errors.mail" />
            <InputText label="Nazwa dealera" type="text" v-model="formData.dealer" :error="errors.dealer" />
            <InputText label="Stanowisko" type="text" v-model="formData.job" :error="errors.job" />
          </div>

          <div class="mt-8  md:gap-6"
               :class="{'md:flex':!fluid,'lg:block lg:items-center':!standalone && !fluid, 'block md:block lg:block items-center xl:flex xl:gap-6':fluid} ">
            <InputCheckBox :class="{'lg:text-xs lg:min-w-[230px]':standalone}" @change="(checked) => formData.agr_m = checked" :error="errors.agr_m" :checked="formData.agr_m">
              <span class="pr-1">{{ texts.form.agreements.mail.small }}</span>
              <ButtonToggle @toggle="(e) => mailExpand = e">
                <template v-slot:default>{{ texts.form.agreements.mail.more }}</template>
                <template v-slot:toggled>{{ texts.form.agreements.mail.less }}</template>
              </ButtonToggle>
              <Accordion :expanded="mailExpand">
                {{ texts.form.agreements.mail.large }}
              </Accordion>
            </InputCheckBox>
            <InputCheckBox :class="{'lg:text-xs lg:min-w-[225px]':standalone}" @change="(checked) => formData.agr_p = checked" :error="errors.agr_p" :checked="formData.agr_p">
              <span class="pr-1">{{ texts.form.agreements.phone.small }}</span>
              <ButtonToggle @toggle="(e) => phoneExpand = e">
                <template v-slot:default>{{ texts.form.agreements.phone.more }}</template>
                <template v-slot:toggled>{{ texts.form.agreements.phone.less }}</template>
              </ButtonToggle>
              <Accordion :expanded="phoneExpand">
                {{ texts.form.agreements.phone.large }}
              </Accordion>
            </InputCheckBox>
            <div class="hidden text-[10px] text-gray-400 font-extralight mt-1"
                 :class="{'lg:block whitespace-nowrap':standalone}">
              {{ texts.form.disclaimer.content }} <a href="/informacje/polityka-prywatnosci" rel="nofollow" class="underline">{{ texts.form.disclaimer.more }}</a>
            </div>
          </div>

          <div class="flex flex-col">
            <div class="my-4 md:order-2 md:mt-2 md:mb-0  md:mx-auto"
                 :class="{'md:w-[300px]':!fluid, 'w-full':fluid, 'lg:order-1 lg:my-3 lg:w-auto lg:mx-0':!standalone, '!order-1': docking}">
              <ButtonRounded color="azure" @click="validateAndSend">{{ texts.form.order }}</ButtonRounded>
            </div>
            <div class="text-[10px] text-gray-400 font-extralight md:order-1 md:mt-2"
                 :class="{'lg:order-2 lg:mt-0':!standalone, 'lg:hidden':standalone, '!order-2':docking}">
              {{ texts.form.disclaimer.content }} <a href="/informacje/polityka-prywatnosci" rel="nofollow" class="underline">{{ texts.form.disclaimer.more }}</a>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<template>
    
<svg width="414" height="51" viewBox="0 0 414 51" fill="none" xmlns="http://www.w3.org/2000/svg">
<line y1="23.6133" x2="100" y2="23.6133" stroke="#0A66A8" stroke-width="2"/>
<line x1="100" y1="23.6133" x2="344" y2="23.6133" stroke="#E5E7EB" stroke-width="2"/>
<rect x="360.5" y="5.11328" width="53" height="39" rx="3.5" fill="white"/>
<rect x="360.5" y="5.11328" width="53" height="39" rx="3.5" stroke="#9CA3AF"/>
<path d="M374.16 26.1973V25.1573H377.408C377.856 25.1573 378.256 25.0666 378.608 24.8853C378.971 24.7039 379.253 24.4479 379.456 24.1173C379.669 23.7759 379.776 23.3706 379.776 22.9013C379.776 22.4319 379.669 22.0319 379.456 21.7013C379.253 21.3599 378.971 21.0986 378.608 20.9173C378.256 20.7253 377.856 20.6293 377.408 20.6293H374.16V19.6053H377.488C378.149 19.6053 378.741 19.7439 379.264 20.0213C379.787 20.2879 380.197 20.6666 380.496 21.1573C380.805 21.6479 380.96 22.2293 380.96 22.9013C380.96 23.5626 380.805 24.1439 380.496 24.6453C380.197 25.1359 379.787 25.5199 379.264 25.7973C378.741 26.0639 378.149 26.1973 377.488 26.1973H374.16ZM373.408 30.6133V19.6053H374.576V30.6133H373.408ZM383.002 30.6133V19.6053H384.17V30.6133H383.002ZM383.77 30.6133V29.5413H389.834V30.6133H383.77ZM391.861 30.6133V19.6053H392.693L393.029 20.9973V30.6133H391.861ZM399.477 30.6133L392.517 21.1733L392.693 19.6053L399.637 29.0453L399.477 30.6133ZM399.477 30.6133L399.125 29.2853V19.6053H400.309V30.6133H399.477Z" fill="#6B7280"/>
<g filter="url(#filter0_ddd_8045_176885)">
<rect x="86" y="4.61328" width="40" height="40" rx="20" fill="white" shape-rendering="crispEdges"/>
<path d="M101 29.6133L96 24.6133L101 19.6133" stroke="#0A66A8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M111 19.6133L116 24.6133L111 29.6133" stroke="#0A66A8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<filter id="filter0_ddd_8045_176885" x="81" y="0.613281" width="50" height="50" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="1.5"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8045_176885"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"/>
<feBlend mode="normal" in2="effect1_dropShadow_8045_176885" result="effect2_dropShadow_8045_176885"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="2.5"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
<feBlend mode="normal" in2="effect2_dropShadow_8045_176885" result="effect3_dropShadow_8045_176885"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_8045_176885" result="shape"/>
</filter>
</defs>
</svg>

</template>
<template>
    
<svg width="180" height="80" viewBox="0 0 180 80" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22.7232 29.7469C22.0681 28.7742 21.8399 27.5335 22.088 26.3722L22.7332 23.3747C23.15 21.4491 24.748 20.1092 26.624 20.1092H32.1624L34.3064 15.335C33.7207 15.335 33.1451 15.335 32.5694 15.335C24.6289 15.335 16.1426 15.3945 8.57931 15.9107L13.0657 4.94293C13.8696 3.17618 15.6364 2.03474 17.5818 2.03474H47.5768C49.5222 2.03474 51.289 3.17618 52.093 4.94293L53.6314 8.71464H56.0235L53.959 4.11911C52.8274 1.60794 50.3361 0 47.5868 0H17.5917C14.8423 0 12.351 1.61787 11.2195 4.11911L8.46021 10.2531H1.82993C1.35351 10.2531 0.926709 10.6402 0.807602 11.1861L0.221994 13.8759C0.0631846 14.5906 0.470132 15.3052 1.08552 15.4144L4.98626 16.3375L3.55698 17.8958C2.72323 18.5906 2.23688 19.6228 2.23688 20.7146V45.4591C2.23688 46.3921 2.99123 47.1563 3.93415 47.1563H9.7406C10.6736 47.1563 11.4379 46.402 11.4379 45.4591V39.4739H24.2716V34.5608C24.2716 33.4392 24.5396 32.3573 25.0458 31.3846C24.1029 31.1464 23.2989 30.5707 22.7332 29.737L22.7232 29.7469ZM15.9441 28.8635C15.8051 28.933 15.6562 28.9727 15.5074 28.9628L5.84978 28.6253C5.10537 28.5955 4.51976 28 4.48998 27.2655L4.40065 24.6055C4.37088 23.8313 4.98626 23.1762 5.76046 23.1563L8.32125 23.0769C8.51976 23.0769 8.71827 23.1067 8.89693 23.1762L16.8473 25.7667C17.2146 25.9156 15.9342 28.8734 15.9342 28.8734L15.9441 28.8635Z" fill="#3C3C44"/>
<path d="M47.6755 41.9842C46.9411 40.9023 46.6929 39.5326 46.9708 38.2323L47.6954 34.9073C48.1619 32.7733 49.9386 31.2844 52.013 31.2844H58.1669L59.3778 28.5946C50.9609 28.5946 42.0974 28.6939 34.1371 29.2398L39.1197 17.0611C40.0031 15.0958 41.9783 13.8254 44.1321 13.8254H77.4622C79.616 13.8254 81.5912 15.0958 82.4746 17.0611L83.1197 18.6393H85.6607L84.5391 16.1479C83.2884 13.3589 80.5093 11.5723 77.4522 11.5723H44.1222C41.0651 11.5723 38.2959 13.3688 37.0353 16.1479L33.9684 22.9569H26.6036C26.0676 22.9569 25.6011 23.3837 25.4721 23.9991L24.817 26.9867C24.6483 27.7807 25.0949 28.5747 25.7798 28.7038L30.1172 29.736L28.5291 31.473C27.5961 32.2472 27.0602 33.3986 27.0602 34.6095V62.1033C27.0602 63.1455 27.9038 63.9891 28.946 63.9891H35.4076C36.4497 63.9891 37.2934 63.1455 37.2934 62.1033V55.4532H49.3728V47.3837C49.3728 46.1331 49.6706 44.9321 50.2264 43.8601C49.1842 43.5921 48.281 42.9569 47.6557 42.0239L47.6755 41.9842ZM42.3157 43.612C42.1669 43.6914 41.9981 43.7311 41.8294 43.7311L31.0999 43.3539C30.276 43.3241 29.621 42.6591 29.5912 41.8452L29.4919 38.8874C29.4622 38.0239 30.147 37.2993 31.0105 37.2695L33.8592 37.1802C34.0775 37.1802 34.2959 37.21 34.5043 37.2993L43.3381 40.1777C43.745 40.3465 42.3257 43.6219 42.3257 43.6219L42.3157 43.612Z" fill="#3C3C44"/>
<path d="M179.762 13.8857L179.177 11.1959C179.058 10.65 178.641 10.2629 178.154 10.2629H171.524L168.765 4.12887C167.633 1.61771 165.142 0.00976562 162.393 0.00976562H132.398C129.648 0.00976562 127.157 1.62763 126.025 4.12887L123.961 8.7244H126.353L127.891 4.95269C128.695 3.18594 130.462 2.04451 132.408 2.04451H162.403C164.348 2.04451 166.115 3.18594 166.919 4.95269L171.405 15.9204C163.842 15.4043 155.355 15.3448 147.415 15.3448C139.475 15.3448 146.254 15.3448 145.678 15.3448L147.822 20.1189H153.36C155.236 20.1189 156.834 21.4589 157.251 23.3845L157.896 26.382C158.154 27.5532 157.916 28.784 157.261 29.7567C156.695 30.5904 155.882 31.1661 154.949 31.4043C155.455 32.377 155.723 33.4589 155.723 34.5805V39.4936H168.556V45.4787C168.556 46.4117 169.311 47.176 170.254 47.176H176.06C176.993 47.176 177.757 46.4217 177.757 45.4787V20.7343C177.757 19.6425 177.271 18.6103 176.437 17.9155L175.008 16.3572L178.909 15.4341C179.524 15.3249 179.931 14.6103 179.772 13.8956L179.762 13.8857ZM175.574 24.6053L175.485 27.2654C175.465 28.0098 174.869 28.6053 174.125 28.6251L164.467 28.9626C164.318 28.9626 164.159 28.9328 164.03 28.8634C164.03 28.8634 162.75 25.9155 163.117 25.7567L171.068 23.1661C171.246 23.0867 171.445 23.0569 171.643 23.0668L174.204 23.1462C174.978 23.176 175.594 23.8212 175.564 24.5954L175.574 24.6053Z" fill="#3C3C44"/>
<path d="M155.131 26.9671L154.485 23.9795C154.356 23.3641 153.89 22.9374 153.354 22.9374H145.989L142.922 16.1284C141.672 13.3393 138.892 11.5527 135.835 11.5527H102.505C99.4482 11.5527 96.679 13.3493 95.4185 16.1284L94.2969 18.6197H96.8477L97.4929 17.0416C98.3763 15.0763 100.351 13.8058 102.505 13.8058H135.835C137.989 13.8058 139.964 15.0763 140.848 17.0416L145.83 29.2202C137.88 28.6743 129.007 28.585 120.59 28.5751L121.801 31.2649H127.954C130.039 31.2649 131.815 32.7537 132.282 34.8877L133.007 38.2128C133.294 39.513 133.036 40.8828 132.302 41.9646C131.677 42.8976 130.773 43.5329 129.731 43.8009C130.287 44.8728 130.585 46.0838 130.585 47.3244V55.3939H142.664V62.044C142.664 63.0862 143.508 63.9299 144.55 63.9299H151.012C152.054 63.9299 152.897 63.0862 152.897 62.044V34.5503C152.897 33.3393 152.361 32.188 151.428 31.4138L149.84 29.6768L154.178 28.6445C154.863 28.5254 155.309 27.7314 155.141 26.9274L155.131 26.9671ZM150.476 38.8778L150.376 41.8356C150.347 42.6594 149.691 43.3244 148.868 43.3443L138.138 43.7215C137.969 43.7215 137.801 43.6818 137.652 43.6024C137.652 43.6024 136.222 40.3269 136.639 40.1582L145.473 37.2798C145.672 37.2004 145.89 37.1607 146.118 37.1607L148.967 37.25C149.83 37.2798 150.505 38.0043 150.476 38.8679V38.8778Z" fill="#3C3C44"/>
<path d="M129.935 38.8991L129.211 35.574C129.062 34.8991 128.546 34.4227 127.95 34.4227H119.771L116.367 26.8594C114.977 23.7626 111.89 21.7676 108.496 21.7676H71.4637C68.0692 21.7676 64.9922 23.7626 63.5927 26.8594L60.1883 34.4227H52.0096C51.4141 34.4227 50.8979 34.8991 50.749 35.574L50.0245 38.8991C49.8359 39.7825 50.3322 40.6658 51.0964 40.8048L55.9103 41.9462L54.1436 43.8718C53.1113 44.7353 52.5059 46.0058 52.5059 47.3557V77.9065C52.5059 79.0579 53.4389 80.0008 54.6002 80.0008H61.7763C62.9277 80.0008 63.8706 79.0678 63.8706 77.9065V70.512H116.079V77.9065C116.079 79.0579 117.012 80.0008 118.173 80.0008H125.35C126.501 80.0008 127.444 79.0678 127.444 77.9065V47.3557C127.444 46.0058 126.848 44.7353 125.806 43.8718L124.039 41.9462L128.853 40.8048C129.618 40.6758 130.114 39.7825 129.925 38.8991H129.935ZM69.4488 57.3904C69.2801 57.4797 69.1014 57.5294 68.9128 57.5194L56.9922 57.1026C56.0791 57.0728 55.3446 56.3383 55.3148 55.4251L55.2056 52.1398C55.1759 51.177 55.9302 50.373 56.883 50.3433L60.0493 50.244C60.2974 50.244 60.5357 50.2837 60.7639 50.373L70.5803 53.5691C71.0369 53.7576 69.4488 57.4003 69.4488 57.4003V57.3904ZM60.3669 41.4103L65.9054 27.8718C66.888 25.6882 69.0816 24.2787 71.4736 24.2787H108.506C110.908 24.2787 113.091 25.6882 114.074 27.8718L119.613 41.4103C110.273 40.775 99.8012 40.6956 89.9947 40.6956C80.1883 40.6956 69.7168 40.775 60.3768 41.4103H60.3669ZM124.764 52.1299L124.655 55.4152C124.625 56.3284 123.89 57.0629 122.977 57.0926L111.057 57.5095C110.868 57.5095 110.68 57.4698 110.521 57.3805C110.521 57.3805 108.933 53.7378 109.389 53.5492L119.206 50.3532C119.434 50.2639 119.672 50.2142 119.92 50.2242L123.087 50.3234C124.049 50.3532 124.804 51.1572 124.764 52.1199V52.1299Z" fill="#0A66A8"/>
</svg>

</template>
<template>
    
<svg width="78" height="80" viewBox="0 0 78 80" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M50.4194 53.4848C47.3724 56.1536 43.3821 57.7793 39.0094 57.7793C34.6367 57.7793 30.6595 56.158 27.6082 53.4935L23.092 56.7969C21.6011 57.8836 20.7188 59.6223 20.7188 61.4653V73.0969C20.7188 74.9311 21.4446 76.6872 22.7443 77.9825C24.0396 79.2778 25.8 80.0037 27.6299 79.9993H50.3846C54.201 79.995 57.2914 76.8958 57.2914 73.0795V61.4174C57.2914 59.5701 56.4047 57.8358 54.9094 56.7535C54.9094 56.7535 50.4194 53.5109 50.4194 53.4805V53.4848Z" fill="#1A1A1A"/>
<path d="M72.8759 46.623C70.8591 48.3878 68.2207 49.4614 65.3258 49.4614C62.4309 49.4614 59.8012 48.3921 57.7844 46.6274L54.7982 48.8094C53.9941 49.3962 53.4508 50.2699 53.2812 51.2479L58.2103 54.8599C60.0185 56.1857 61.0878 58.3068 61.0878 60.5714V64.1704H72.8542C75.3796 64.1704 77.4269 62.1188 77.4269 59.5891V51.8781C77.4269 50.6567 76.8401 49.5092 75.8534 48.792C75.8534 48.792 72.8803 46.6448 72.8803 46.6274L72.8759 46.623Z" fill="#1A1A1A"/>
<path d="M20.2293 46.6215C18.2168 48.3863 15.574 49.4599 12.6792 49.4599C9.78432 49.4599 7.15894 48.3906 5.13776 46.6259L2.14726 48.8079C1.16057 49.5294 0.578125 50.677 0.578125 51.8984V59.5963C0.578125 60.809 1.0606 61.9739 1.91689 62.8302C2.77318 63.6865 3.93808 64.1689 5.15079 64.1646H16.9128V60.6221C16.9128 58.3618 17.9777 56.2363 19.7816 54.9019L24.7237 51.2377C24.5586 50.251 24.0109 49.3686 23.2024 48.7818C23.2024 48.7818 20.2293 46.6346 20.2293 46.6172V46.6215Z" fill="#1A1A1A"/>
<path d="M12.5776 30.5781C8.52214 30.6346 5.25781 33.9337 5.25781 37.9935C5.25781 42.0533 8.57431 45.4089 12.6732 45.4089C16.7721 45.4089 20.0929 42.088 20.0929 37.9935C20.0929 33.899 16.7677 30.5781 12.6732 30.5781H12.5819H12.5776Z" fill="#1A1A1A"/>
<path d="M65.2286 30.543C61.1602 30.5995 57.8828 33.9116 57.8828 37.9801C57.8828 42.0485 61.2124 45.4215 65.3243 45.4215C69.4362 45.4215 72.7657 42.0877 72.7657 37.9801C72.7657 33.8725 69.4318 30.543 65.2286 30.543Z" fill="#1A1A1A"/>
<path d="M38.8625 29.2305C32.7338 29.3174 27.8047 34.2987 27.8047 40.4274C27.8047 46.5562 32.8164 51.6287 39.0016 51.6287C45.1869 51.6287 50.2073 46.6127 50.2073 40.4274C50.2073 34.2422 45.1913 29.2305 39.0016 29.2305C38.9495 29.2305 38.9031 29.2305 38.8625 29.2305Z" fill="#1A1A1A"/>
<path d="M31.7444 0C29.9405 0 28.4844 1.45612 28.4844 3.25998V16.0478C28.4844 17.8517 29.9405 19.3078 31.7444 19.3078H35.3043L39.0033 23.5067L42.7022 19.3078H46.2621C48.066 19.3078 49.5221 17.856 49.5221 16.0478V3.25998C49.5221 1.45612 48.066 0 46.2621 0H31.74H31.7444ZM40.1725 4.2771C40.4246 4.2771 40.6724 4.39445 40.8506 4.56397C41.1809 4.87693 41.3504 5.35072 41.3287 5.80712C41.2983 6.26352 41.1679 6.71122 40.9592 7.1198C40.8288 7.3806 40.6724 7.65009 40.5811 7.92393C40.4811 8.23689 40.7202 8.56723 41.0505 8.54115C41.6938 8.51507 42.5936 8.51508 43.2369 8.57593C43.4803 8.60201 43.7367 8.62374 43.9497 8.74545C44.4061 9.0019 44.5365 9.68867 44.2149 10.0973C44.1584 10.1711 44.0714 10.245 44.0714 10.3407C44.041 10.4493 44.154 10.5406 44.2192 10.6275C44.3105 10.7492 44.3627 10.8927 44.3844 11.0405C44.4192 11.323 44.3279 11.6273 44.1236 11.8229C44.0671 11.8794 44.0062 11.9315 43.9802 12.0141C43.9497 12.0924 44.0236 12.1706 44.0627 12.2445C44.2931 12.64 44.1671 13.2051 43.7889 13.4659C43.7237 13.5094 43.6585 13.5441 43.6194 13.6093C43.5411 13.7267 43.5629 13.8745 43.5846 14.0179C43.615 14.37 43.3282 14.6786 43.0109 14.8308C42.6936 14.9785 42.0807 14.9959 41.7286 15.022C39.9682 15.0742 38.2078 14.9394 36.4518 14.8134C36.4518 13.166 36.4213 11.523 36.4213 9.87558C36.9038 9.90166 37.3863 9.63651 37.6254 9.20185C37.8644 8.76718 37.9601 8.25862 38.2165 7.83265C38.5295 7.29802 39.0728 6.91986 39.3858 6.38522C39.677 5.8897 39.7509 5.3029 39.7813 4.73784C39.7813 4.66829 39.8117 4.5944 39.8117 4.52051C39.8117 4.44661 39.7813 4.40749 39.8422 4.36837C39.8987 4.30317 40.0769 4.28144 40.1638 4.2771H40.1725ZM33.9481 9.87992H35.3912C35.5868 9.87992 35.7476 10.0407 35.7476 10.232V14.483C35.7476 14.6743 35.5911 14.8351 35.3912 14.8351H33.9438C33.7482 14.8351 33.5873 14.6786 33.5873 14.483V10.232C33.5873 10.0407 33.7438 9.87992 33.9438 9.87992H33.9481Z" fill="#0A66A8"/>
<path d="M60.3028 10.7676C59.0553 10.7676 58.0469 11.7717 58.0469 13.0235V21.8646C58.0469 23.112 59.0553 24.1205 60.3028 24.1205H62.763L65.3232 27.024L67.8833 24.1205H70.3479C71.5954 24.1205 72.6038 23.1164 72.6038 21.8646V13.0235C72.6038 11.776 71.5997 10.7676 70.3479 10.7676H60.3071H60.3028ZM66.1316 13.7276C66.3055 13.7276 66.475 13.8102 66.6011 13.9276C66.8314 14.1449 66.9488 14.4709 66.9314 14.7882C66.9053 15.1055 66.8184 15.4141 66.675 15.6967C66.5837 15.8749 66.475 16.0618 66.4142 16.253C66.3446 16.4704 66.5098 16.6964 66.7402 16.6834C67.1879 16.6573 67.8094 16.6573 68.2528 16.7094C68.4223 16.7355 68.5962 16.7399 68.7483 16.8268C69.0613 17.005 69.1569 17.4788 68.9309 17.7613C68.8874 17.8135 68.8352 17.8613 68.8352 17.9308C68.8092 18.0047 68.8918 18.0743 68.9352 18.1308C69.0004 18.2134 69.0352 18.3134 69.0526 18.4177C69.0787 18.6089 69.0135 18.8219 68.87 18.961C68.8309 19.0001 68.7874 19.0349 68.7744 19.0957C68.7483 19.1479 68.8005 19.2044 68.8309 19.2566C68.9917 19.5304 68.9048 19.9216 68.6396 20.1042C68.5962 20.1302 68.5484 20.1563 68.5223 20.1998C68.4701 20.2824 68.4788 20.3867 68.4962 20.4823C68.5223 20.7257 68.318 20.9387 68.0963 21.043C67.879 21.143 67.453 21.1604 67.2096 21.1778C65.9925 21.2126 64.7755 21.1213 63.5584 21.0343C63.5584 19.8955 63.5323 18.7567 63.5323 17.6222C63.867 17.6483 64.2017 17.4527 64.3669 17.1571C64.5364 16.8572 64.5973 16.5051 64.7755 16.2096C64.9928 15.8401 65.3666 15.5793 65.584 15.2098C65.7839 14.8665 65.8361 14.4622 65.8578 14.071C65.8578 14.0232 65.8839 13.9711 65.8839 13.9232C65.8839 13.8754 65.8578 13.8493 65.91 13.8189C65.9491 13.7711 66.0708 13.7581 66.1316 13.7494V13.7276ZM61.8241 17.6005H62.8238C62.9586 17.6005 63.0716 17.7092 63.0716 17.8439V20.7822C63.0716 20.917 62.9629 21.03 62.8282 21.03H61.8285C61.6937 21.03 61.585 20.9213 61.585 20.7822V17.8439C61.585 17.7092 61.6937 17.6005 61.8285 17.6005H61.8241Z" fill="#0A66A8"/>
<path d="M7.65435 10.7676C6.40686 10.7676 5.39844 11.7717 5.39844 13.0235V21.8646C5.39844 23.112 6.40686 24.1205 7.65435 24.1205H10.1145L12.6747 27.024L15.2349 24.1205H17.6994C18.9469 24.1205 19.9553 23.1164 19.9553 21.8646V13.0235C19.9553 11.776 18.9513 10.7676 17.6994 10.7676H7.65869H7.65435ZM13.4832 13.7276C13.6571 13.7276 13.8266 13.8102 13.9526 13.9276C14.183 14.1449 14.3004 14.4709 14.283 14.7882C14.2569 15.1055 14.17 15.4141 14.0265 15.6967C13.9352 15.8749 13.8266 16.0618 13.7657 16.253C13.6962 16.4704 13.8614 16.6964 14.0917 16.6834C14.5394 16.6573 15.1566 16.6573 15.6044 16.7094C15.7739 16.7355 15.9477 16.7399 16.0999 16.8268C16.4128 17.005 16.5085 17.4788 16.2824 17.7613C16.239 17.8135 16.1868 17.8613 16.1868 17.9308C16.1607 18.0047 16.2433 18.0743 16.2868 18.1308C16.352 18.2134 16.3868 18.3134 16.4041 18.4177C16.4302 18.6089 16.365 18.8219 16.2216 18.961C16.1825 19.0001 16.139 19.0349 16.126 19.0957C16.0999 19.1479 16.152 19.2044 16.1825 19.2566C16.3433 19.5304 16.2564 19.9216 15.9912 20.1042C15.9477 20.1302 15.8999 20.1563 15.8738 20.1998C15.8217 20.2824 15.8304 20.3867 15.8478 20.4823C15.8738 20.7257 15.6696 20.9387 15.4479 21.043C15.2305 21.143 14.8046 21.1604 14.5612 21.1778C13.3441 21.2126 12.127 21.1213 10.91 21.0343C10.91 19.8955 10.8839 18.7567 10.8839 17.6222C11.2186 17.6483 11.5533 17.4527 11.7185 17.1571C11.888 16.8572 11.9488 16.5051 12.127 16.2096C12.3444 15.8401 12.7182 15.5793 12.9355 15.2098C13.1355 14.8665 13.1876 14.4622 13.2094 14.071C13.2094 14.0232 13.2354 13.9711 13.2354 13.9232C13.2354 13.8754 13.2094 13.8493 13.2615 13.8189C13.3006 13.7711 13.4223 13.7581 13.4832 13.7494V13.7276ZM9.18002 17.6005H10.1797C10.3145 17.6005 10.4275 17.7092 10.4275 17.8439V20.7822C10.4275 20.917 10.3188 21.03 10.1797 21.03H9.18002C9.04527 21.03 8.93226 20.9213 8.93226 20.7822V17.8439C8.93226 17.7092 9.04093 17.6005 9.17567 17.6005H9.18002Z" fill="#0A66A8"/>
</svg>

</template>
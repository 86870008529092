<template>
    
<svg width="160" height="161" viewBox="0 0 160 161" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="mask0_8045_176909" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="20" y="42" width="139" height="118">
<path d="M158.052 159.474H50.9915L20.8203 73.8651L50.9915 42.7031H158.052V159.474Z" fill="white"/>
</mask>
<g mask="url(#mask0_8045_176909)">
<path d="M157.403 56.2296V208.77C157.403 215.161 152.201 220.363 145.81 220.363H115.292H76.7977C74.2711 220.214 71.9921 219.273 70.1591 217.787C67.5333 215.656 65.8984 212.436 65.8984 208.819V56.2296C65.8984 49.8387 71.1004 44.6367 77.4913 44.6367H145.81C152.201 44.6367 157.403 49.8387 157.403 56.2296Z" fill="#101C3A"/>
<path d="M154.829 56.2296V208.77C154.829 213.774 150.766 217.787 145.812 217.787H77.4932C72.4895 217.787 68.4766 213.724 68.4766 208.77V56.2296C68.4766 51.2258 72.539 47.2129 77.4932 47.2129H145.812C150.816 47.2129 154.829 51.2754 154.829 56.2296Z" fill="white"/>
<path d="M122.424 48.6992H101.17C100.675 48.6992 100.328 49.0956 100.328 49.5414C100.328 49.9873 100.724 50.3837 101.17 50.3837H122.424C122.919 50.3837 123.266 49.9873 123.266 49.5414C123.266 49.0956 122.87 48.6992 122.424 48.6992Z" fill="#101C3A"/>
<path d="M79.1969 55.3226L76.3594 58.1602L76.7097 58.5105L79.5472 55.6729L79.1969 55.3226Z" fill="#101C3A"/>
<path d="M76.6628 55.1887L76.3125 55.5391L79.1501 58.3766L79.5004 58.0263L76.6628 55.1887Z" fill="#101C3A"/>
<path d="M146.699 57.5176H142.141V58.013H146.699V57.5176Z" fill="#361E4D"/>
<path d="M87.8426 139.362C85.5637 141.393 81.9967 144.663 82.0958 147.635C82.1949 150.608 93.4905 149.121 93.4905 149.121L120.838 107.803L103.696 104.385C103.696 104.385 90.1216 137.33 87.8426 139.362Z" fill="black"/>
<path d="M113.469 112.466L93.3438 143.936L93.8028 144.229L113.929 112.76L113.469 112.466Z" fill="white"/>
<path d="M133.072 98.6876L87.2953 53.5547L61.9297 103.047L124.997 114.541C127.474 115.78 131.289 113.303 133.518 108.893C135.748 104.534 135.599 99.9757 133.122 98.6876H133.072Z" fill="#0A66A8"/>
<path d="M133.077 98.6871L127.231 92.8906C127.379 97.2999 126.438 106.168 117.719 113.153L125.001 114.491C127.479 115.73 131.293 113.252 133.523 108.843C135.752 104.483 135.603 99.9256 133.126 98.6375L133.077 98.6871Z" fill="black"/>
<path d="M87.4434 51.6728C85.8581 50.8801 84.1736 50.3847 82.3406 50.2856C73.5221 49.6911 63.1678 57.5187 57.1236 69.2602C49.5932 83.9742 51.0795 99.2828 60.542 104.138C62.1274 104.931 63.8118 105.426 65.6449 105.525C74.4634 106.12 84.8177 98.2919 90.8618 86.5504C98.3922 71.8364 96.906 56.5279 87.4434 51.6728Z" fill="#390D9F"/>
<path d="M86.4092 53.6536C78.1356 49.4425 65.8987 56.8243 59.0124 70.2502C52.126 83.6266 53.2655 97.9443 61.539 102.155C69.8126 106.366 82.0495 98.9847 88.9358 85.5587C95.8222 72.1823 94.6827 57.8647 86.4092 53.6536Z" fill="white"/>
<path d="M61.8357 102.552C61.687 102.503 61.588 102.453 61.4393 102.354C53.0667 98.0438 51.8777 83.5775 58.8136 70.1021C65.7495 56.6266 78.1846 49.0962 86.5572 53.4063C94.9298 57.7165 96.1189 72.1828 89.1829 85.6583C82.3461 98.9356 70.2083 106.416 61.8357 102.552ZM85.9132 53.7036C77.7882 49.9384 65.9477 57.3202 59.2595 70.3498C52.4722 83.5775 53.5621 97.7466 61.687 101.908C69.7624 106.07 81.9498 98.6879 88.6875 85.4601C95.4748 72.2324 94.3849 58.0633 86.26 53.9018C86.1609 53.8522 86.0122 53.8027 85.9132 53.7036Z" fill="#101C3A"/>
<path d="M80.414 74.9082L76.3516 83.8258L84.526 92.4461C86.1609 90.3654 87.5976 88.0864 88.8857 85.5598C90.2729 82.7854 91.3628 80.011 92.106 77.3358L80.9094 74.9082H80.414Z" fill="black"/>
<path d="M80.905 74.9068C79.5179 74.2132 77.3876 75.6004 76.0995 78.0775C74.8609 80.5546 74.96 83.1308 76.3472 83.8244C77.7344 84.518 79.8647 83.1308 81.1528 80.6537C82.3913 78.1766 82.2922 75.6004 80.905 74.9068Z" fill="black"/>
<path d="M54.8158 48.2848L53.9688 48.9707L60.8279 57.441L61.6749 56.7551L54.8158 48.2848Z" fill="#101C3A"/>
<path d="M50.4831 88.7636L29.7578 90.6133L29.8547 91.6989L50.58 89.8492L50.4831 88.7636Z" fill="#101C3A"/>
<path d="M42.0048 75.7177L41.7578 76.7793L49.4783 78.5759L49.7253 77.5143L42.0048 75.7177Z" fill="#101C3A"/>
<path d="M32.8616 66.4862L32.4375 67.4902L50.4643 75.1051L50.8884 74.101L32.8616 66.4862Z" fill="#101C3A"/>
</g>
<path d="M95.9756 27.8898C91.4672 37.4019 80.0725 41.5139 70.5604 36.956C61.0483 32.4477 56.9363 21.053 61.4942 11.5409C66.0026 2.02881 77.3972 -2.0832 86.9093 2.47468C96.4214 6.98302 100.533 18.3777 95.9756 27.8898Z" fill="black"/>
<path d="M87.5504 13.7206C85.5192 12.0361 82.7448 11.9866 80.6145 13.3242C79.2273 11.0453 76.552 9.9058 73.8767 10.6985C70.6565 11.6893 68.7244 15.2068 69.6161 18.6252C70.5079 22.0436 77.2952 29.475 77.2952 29.475C77.2952 29.475 86.3614 25.462 88.4917 22.6877C90.5725 19.9133 90.1761 15.9004 87.5504 13.7206Z" fill="white"/>
<path d="M33.5042 132.078L13.7369 122.566C13.1424 122.268 13.1919 121.377 13.836 121.179L48.6641 112.36C49.3082 112.211 49.8036 113.004 49.4073 113.549L36.6749 131.137C35.8327 132.276 34.5942 132.623 33.5538 132.078H33.5042Z" fill="black"/>
<path d="M27.7072 131.384L19.6814 142.878C19.3346 143.373 18.5419 143.225 18.3933 142.63L13.984 125.142C13.8354 124.547 14.4299 124.052 15.0244 124.3L27.509 130.294C27.9054 130.492 28.054 130.988 27.8063 131.384H27.7072Z" fill="black"/>
<path d="M54.8542 135.893C54.8542 135.992 54.7551 136.091 54.5569 136.141L20.1251 144.86C19.927 144.86 19.8279 144.86 19.7783 144.761C19.7288 144.662 19.7783 144.513 19.8279 144.315C19.8279 144.265 19.927 144.216 19.9765 144.117L28.6959 131.682C28.9932 131.285 29.4886 131.137 29.9345 131.335L33.0061 132.821C33.7492 133.168 34.5914 133.267 35.3841 133.069C36.1272 132.871 36.8704 132.425 37.3658 131.731L39.4466 128.858C39.7438 128.461 40.2888 128.313 40.6851 128.561L54.3588 135.397C54.4579 135.397 54.5074 135.447 54.5569 135.496C54.7551 135.596 54.8542 135.744 54.8046 135.843L54.8542 135.893Z" fill="black"/>
<path d="M50.4005 115.878L54.7603 133.168C54.9089 133.763 54.2648 134.308 53.7199 134.01L41.3343 127.818C40.938 127.619 40.7894 127.075 41.0866 126.728L49.1125 115.63C49.4592 115.135 50.2519 115.283 50.4005 115.878Z" fill="black"/>
<path d="M159.983 159.473H9.375V160.612H159.983V159.473Z" fill="#131313"/>
<path d="M39.8441 25.0163L3.87647 32.943C1.2012 33.5375 -0.483237 36.3119 0.160811 39.1358L5.51137 62.9656C6.15541 65.7895 8.88023 67.6225 11.5555 67.028L36.7229 61.3802L50.8424 67.4244L47.5727 59.1013C50.2479 58.5068 51.9324 55.7324 51.2883 52.9085L45.9378 29.0787C45.2937 26.2548 42.5689 24.4218 39.8936 25.0163H39.8441Z" fill="black"/>
<path d="M15.7201 47.6575C15.3733 45.973 13.7384 44.9326 12.054 45.2794C10.3695 45.6262 9.32913 47.2611 9.67593 48.9456C10.0227 50.63 11.6576 51.6704 13.342 51.3236C15.0265 50.9768 16.0669 49.3419 15.7201 47.6575Z" fill="white"/>
<path d="M27.4076 45.1301C27.0608 43.4457 25.4259 42.4053 23.7414 42.7521C22.057 43.0989 21.0166 44.7338 21.3634 46.4182C21.7102 48.1027 23.3451 49.143 25.0295 48.7963C26.714 48.4495 27.7544 46.8146 27.4076 45.1301Z" fill="white"/>
<path d="M39.1498 42.6028C38.803 40.9183 37.1681 39.878 35.4836 40.2248C33.7992 40.5716 32.7588 42.2064 33.1056 43.8909C33.4524 45.5753 35.0873 46.6157 36.7717 46.2689C38.4562 45.9221 39.4966 44.2872 39.1498 42.6028Z" fill="white"/>
</svg>

</template>
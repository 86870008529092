<template>
    <div class="w-full">
        <SubContentHeader>Chętnie odpowiemy na Twoje pytania</SubContentHeader>
        <div class="relative flex w-full mt-[168px] 2xl:mt-0 2xl:items-center">
            <NuxtImg src="/t.patrzykowski.jpg" class="z-10 hidden 2xl:block w-[200px] h-[200px] rounded-full"></NuxtImg>
            <div class="shadow-box w-full max-w-[624px] xl:max-w-full pt-20 px-12 pb-12 2xl:px-20 2xl:flex 2xl:justify-between 2xl:-ml-[38px]">
                <NuxtImg src="/t.patrzykowski.jpg" class="2xl:hidden w-[200px] h-[200px] absolute -top-[26rem] sm:-top-[38rem] md:-top-[26rem] inset-0 m-auto rounded-full"></NuxtImg>
                <div class="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 2xl:flex 2xl:justify-between 2xl:w-full">
                    <div>
                        <p class="text-azure-dark text-xl font-normal mb-4">Tomasz Patrzykowski</p>
                        <p class="text-base font-light mb-4">Dyrektor ds. Sprzedaży</p>
                        <p class="block mb-4 text-azure">
                            <IconPhone stroke="#0A66A8" class="inline-block mr-2" />
                            <a href="tel:+48602414305">+48 602 414 305</a>
                        </p>
                        <p class="block pb-2 text-azure">
                            <IconMail stroke="#0A66A8" class="w-[18px] h-[18px] text-azure-dark inline-block mr-2" />
                        <a href="mailto:tpatrzykowski@vehis.pl">tpatrzykowski@vehis.pl</a>
                        </p>
                    </div>
                    <div class="flex justify-center md:justify-end">
                        <VectorQrContact class="hidden mt-12 md:mt-0 sm:block w-[148px] h-[148px]"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
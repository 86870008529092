<template>
<svg width="218" height="131" viewBox="0 0 218 131" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22.8948 119.161C21.7429 119.161 20.8281 118.246 20.8281 117.094V6.5084C20.8281 3.32364 23.4369 0.714844 26.6217 0.714844H191.484C194.669 0.714844 197.278 3.32364 197.278 6.5084V117.094C197.278 118.246 196.363 119.161 195.211 119.161H22.8948Z" fill="url(#paint0_radial_8010_175492)"/>
<path d="M191.453 0.850443C194.57 0.850443 197.111 3.39148 197.111 6.50848V117.094C197.111 118.178 196.23 119.059 195.146 119.059H22.8975C21.8134 119.059 20.9325 118.178 20.9325 117.094V6.50848C20.9325 3.39148 23.4735 0.850443 26.5905 0.850443H191.453ZM191.453 0.647161H26.5905C23.338 0.647161 20.6953 3.28984 20.6953 6.54236V117.128C20.6953 118.314 21.6778 119.297 22.8637 119.297H195.146C196.332 119.297 197.314 118.314 197.314 117.128V6.50848C197.314 3.25595 194.671 0.613281 191.419 0.613281L191.453 0.647161Z" fill="#505156"/>
<path d="M217.335 118.82H0.669481C0.519788 118.82 0.398438 118.942 0.398438 119.091V122.513C0.398438 122.663 0.519788 122.784 0.669481 122.784H217.335C217.485 122.784 217.606 122.663 217.606 122.513V119.091C217.606 118.942 217.485 118.82 217.335 118.82Z" fill="url(#paint1_linear_8010_175492)"/>
<path d="M217.606 119.061H0.398438V122.55H217.606V119.061Z" fill="url(#paint2_radial_8010_175492)"/>
<path d="M209.407 125.394H8.56339C3.37968 125.394 0.703125 124.039 0.703125 122.785H217.267C217.267 124.106 214.252 125.394 209.407 125.394Z" fill="url(#paint3_linear_8010_175492)"/>
<path d="M96.4838 121.671C95.0269 121.671 93.875 120.486 93.875 119.063V118.961H124.096V119.063C124.096 120.519 122.911 121.671 121.488 121.671H96.4838Z" fill="url(#paint4_linear_8010_175492)"/>
<path d="M123.997 119.063C123.997 120.452 122.879 121.57 121.49 121.57H96.4865C95.0974 121.57 93.9794 120.452 93.9794 119.063H123.997ZM124.201 118.826H93.7422V119.029C93.7422 120.52 94.9619 121.74 96.4526 121.74H121.456C122.947 121.74 124.167 120.52 124.167 119.029V118.826H124.201Z" fill="white"/>
<path style="mix-blend-mode:multiply" d="M209.407 125.394H8.56339C3.37968 125.394 0.703125 124.039 0.703125 122.785H217.267C217.267 124.106 214.252 125.394 209.407 125.394Z" fill="url(#paint5_radial_8010_175492)"/>
<path style="mix-blend-mode:multiply" d="M209.407 125.396H8.56339C3.37968 125.396 0.703125 128.141 0.703125 130.614H217.267C217.267 128.005 214.252 125.396 209.407 125.396Z" fill="url(#paint6_linear_8010_175492)"/>
<g style="mix-blend-mode:multiply">
<path d="M93.9766 119.059H123.995C123.995 120.448 122.877 121.566 121.487 121.566H96.4837C95.0946 121.566 93.9766 120.448 93.9766 119.059Z" fill="url(#paint7_radial_8010_175492)"/>
</g>
<path d="M107.961 4.64472C107.961 4.03487 108.435 3.56055 109.045 3.56055C109.655 3.56055 110.129 4.03487 110.129 4.64472C110.129 5.25457 109.655 5.7289 109.045 5.7289C108.435 5.7289 107.961 5.25457 107.961 4.64472Z" fill="url(#paint8_radial_8010_175492)"/>
<path d="M108.6 5.08543C108.363 4.84827 108.363 4.47558 108.6 4.23842C108.837 4.00126 109.21 4.00126 109.447 4.23842C109.684 4.47558 109.684 4.84827 109.447 5.08543C109.21 5.32259 108.837 5.32259 108.6 5.08543Z" fill="url(#paint9_radial_8010_175492)"/>
<g style="mix-blend-mode:soft-light">
<path d="M26.5799 0.849609C23.4629 0.849609 20.9219 3.39064 20.9219 6.50764V117.093C20.9219 118.178 21.8028 119.059 22.8869 119.059H38.2348L106.47 0.849609H26.5799Z" fill="url(#paint10_radial_8010_175492)"/>
</g>
<path d="M192.137 8.27148H25.9531V110.319H192.137V8.27148Z" fill="url(#paint11_radial_8010_175492)" stroke="#050000" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M67.1864 60.1435C66.4749 59.0594 66.2039 57.7041 66.4749 56.4167L67.1864 53.1303C67.6607 51.0297 69.3886 49.539 71.4553 49.539H77.5538L78.2653 47.9466C77.3505 47.9466 76.4358 47.9466 75.521 47.9466C67.2542 47.9466 58.4452 48.0143 50.585 48.5564L55.2266 37.1726C56.0736 35.343 57.9031 34.1572 59.9021 34.1572H91.0721C93.0372 34.1572 94.8328 35.2753 95.6799 37.0371H98.0176L97.7127 36.3256C96.5269 33.7168 93.952 32.0566 91.106 32.0566H59.936C57.09 32.0566 54.4812 33.7168 53.3293 36.3256L50.4494 42.6951H43.5717C43.0635 42.6951 42.6231 43.1017 42.5214 43.6776L41.9116 46.4897C41.7422 47.2351 42.1826 47.9805 42.7925 48.0821L46.8581 49.0307L45.3674 50.657C44.4865 51.3685 43.9783 52.4527 43.9783 53.5707V79.286C43.9783 80.2685 44.7575 81.0478 45.7401 81.0478H51.7708C52.7533 81.0478 53.5326 80.2685 53.5326 79.286V73.052H68.8465V65.4628C68.8465 64.2431 69.1515 63.0234 69.6935 61.9731C68.6771 61.702 67.7624 61.0922 67.1525 60.1435H67.1864ZM58.242 62.007C58.1064 62.0747 57.937 62.1086 57.8015 62.1086L47.7729 61.7698C46.9936 61.7698 46.3838 61.1261 46.3499 60.3468L46.2483 57.5686C46.2483 56.7555 46.8581 56.0779 47.6712 56.044L50.3478 55.9424C50.5511 55.9424 50.7544 55.9424 50.9577 56.044L59.2245 58.7206C59.5972 58.89 58.2758 61.9392 58.2758 61.9392L58.242 62.007Z" fill="#3B3B43"/>
<path d="M176.215 46.4565L175.606 43.6444C175.47 43.0685 175.03 42.6619 174.555 42.6619H167.678L164.798 36.2924C163.612 33.6836 161.037 32.0234 158.191 32.0234H127.021C124.175 32.0234 121.566 33.6836 120.414 36.2924L120.109 37.0039H122.447C123.294 35.2421 125.09 34.124 127.055 34.124H158.225C160.258 34.124 162.087 35.3098 162.9 37.1394L167.542 48.5232C159.682 47.9811 150.873 47.9134 142.606 47.9134C134.339 47.9134 140.776 47.9134 139.862 47.9134L140.573 49.5058H146.672C148.738 49.5058 150.5 50.9965 150.941 53.0971L151.652 56.3835C151.923 57.6709 151.652 59.0262 150.941 60.1103C150.331 61.0251 149.416 61.6688 148.4 61.9399C148.942 62.9902 149.247 64.2099 149.247 65.4296V73.0188H164.561V79.2528C164.561 80.2353 165.34 81.0146 166.322 81.0146H172.353C173.336 81.0146 174.115 80.2353 174.115 79.2528V53.5375C174.115 52.4195 173.607 51.3353 172.726 50.6238L171.235 48.9975L175.301 48.0489C175.944 47.9473 176.351 47.2019 176.182 46.4565H176.215ZM171.845 57.5693L171.743 60.3475C171.743 61.1268 171.099 61.7366 170.32 61.7705L160.292 62.1093C160.122 62.1093 159.987 62.1093 159.851 62.0076C159.851 62.0076 158.53 58.9584 158.903 58.789L167.169 56.1124C167.373 56.0447 167.576 56.0108 167.779 56.0108L170.456 56.1124C171.269 56.1124 171.913 56.8239 171.879 57.6371L171.845 57.5693Z" fill="#3B3B43"/>
<path d="M148.599 57.0952L147.887 53.8088C147.752 53.1312 147.243 52.6569 146.634 52.6569H138.536L135.182 45.1693C133.793 42.12 130.744 40.1211 127.389 40.1211H90.7308C87.3766 40.1211 84.3274 42.0862 82.9383 45.1693L79.5841 52.6569H71.4867C70.9107 52.6569 70.4025 53.1312 70.2331 53.8088L69.5216 57.0952C69.3183 57.9761 69.8265 58.857 70.5719 58.9925L75.3491 60.1106L73.5873 62.0079C72.5709 62.8549 71.961 64.1085 71.961 65.4637V95.685C71.961 96.837 72.8758 97.7517 74.0277 97.7517H81.1426C82.2945 97.7517 83.2093 96.837 83.2093 95.685V88.3669H134.877V95.685C134.877 96.837 135.792 97.7517 136.944 97.7517H144.059C145.211 97.7517 146.125 96.837 146.125 95.685V65.4637C146.125 64.1423 145.549 62.8549 144.499 62.0079L142.737 60.1106L147.514 58.9925C148.26 58.857 148.768 57.9761 148.565 57.0952H148.599ZM88.7318 75.3906C88.5624 75.4923 88.393 75.5262 88.1897 75.5262L76.3993 75.1196C75.4846 75.1196 74.7731 74.3742 74.7392 73.4595L74.6376 70.2069C74.6376 69.2583 75.3491 68.479 76.2977 68.4452L79.4147 68.3435C79.6519 68.3435 79.889 68.3774 80.1262 68.479L89.8499 71.6299C90.2903 71.7993 88.7318 75.4245 88.7318 75.4245V75.3906ZM79.7535 59.5685L85.2421 46.1857C86.2247 44.0173 88.393 42.6283 90.7647 42.6283H127.423C129.795 42.6283 131.963 44.0173 132.946 46.1857L138.434 59.6024C129.185 58.9586 118.818 58.8909 109.128 58.8909C99.4381 58.8909 89.0368 58.9586 79.8213 59.6024L79.7535 59.5685ZM143.483 70.1731L143.381 73.4256C143.381 74.3404 142.636 75.0518 141.721 75.0857L129.93 75.4923C129.727 75.4923 129.558 75.4584 129.388 75.3568C129.388 75.3568 127.83 71.7654 128.27 71.5622L137.994 68.4113C138.231 68.3096 138.468 68.2758 138.706 68.2758L141.823 68.3774C142.771 68.3774 143.517 69.1905 143.483 70.1392V70.1731Z" fill="#0A66A8"/>
<defs>
<radialGradient id="paint0_radial_8010_175492" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(108.477 51.129) scale(100.76 100.76)">
<stop offset="0.5" stop-color="#2B2A2A"/>
<stop offset="0.8" stop-color="#292828"/>
<stop offset="0.9" stop-color="#161616"/>
<stop offset="1" stop-color="#070707"/>
</radialGradient>
<linearGradient id="paint1_linear_8010_175492" x1="108.985" y1="122.75" x2="108.985" y2="118.854" gradientUnits="userSpaceOnUse">
<stop offset="0.2" stop-color="#D6D8DA"/>
<stop offset="0.3" stop-color="#E0E1E2"/>
<stop offset="0.5" stop-color="#EAEAEC"/>
<stop offset="0.8" stop-color="#EEEEEF"/>
</linearGradient>
<radialGradient id="paint2_radial_8010_175492" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(108.985 120.788) scale(108.79 108.79)">
<stop stop-color="#F0F2F3"/>
<stop offset="0.9" stop-color="#8B8B95"/>
<stop offset="1" stop-color="#77797E"/>
</radialGradient>
<linearGradient id="paint3_linear_8010_175492" x1="108.985" y1="122.819" x2="108.985" y2="125.326" gradientUnits="userSpaceOnUse">
<stop stop-color="#AFB0B4"/>
<stop offset="0.2" stop-color="#B3B4B8"/>
<stop offset="0.9" stop-color="#68696D"/>
<stop offset="1" stop-color="#8B8B95"/>
</linearGradient>
<linearGradient id="paint4_linear_8010_175492" x1="108.986" y1="119.096" x2="108.986" y2="121.705" gradientUnits="userSpaceOnUse">
<stop stop-color="#B8BABF"/>
<stop offset="0.2" stop-color="#D2D2D6"/>
<stop offset="0.4" stop-color="#E1E1E4"/>
<stop offset="0.6" stop-color="#E7E7E9"/>
<stop offset="0.8" stop-color="#E3E3E6"/>
<stop offset="0.9" stop-color="#D9DADE"/>
<stop offset="1" stop-color="#D4D5DA"/>
</linearGradient>
<radialGradient id="paint5_radial_8010_175492" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(108.985 124.106) scale(108.587 108.587)">
<stop offset="0.4" stop-color="white"/>
<stop offset="0.6" stop-color="#FCFCFC"/>
<stop offset="0.7" stop-color="#F4F4F5"/>
<stop offset="0.8" stop-color="#E6E6E8"/>
<stop offset="0.9" stop-color="#B8B8BE"/>
<stop offset="1" stop-color="#8B8B95"/>
</radialGradient>
<linearGradient id="paint6_linear_8010_175492" x1="108.985" y1="130.445" x2="108.985" y2="125.43" gradientUnits="userSpaceOnUse">
<stop stop-color="white"/>
<stop offset="0.2" stop-color="#FBFBFB"/>
<stop offset="0.4" stop-color="#F0F0F2"/>
<stop offset="0.6" stop-color="#DEDEE1"/>
<stop offset="0.7" stop-color="#C5C5CA"/>
<stop offset="0.9" stop-color="#A5A5AD"/>
<stop offset="1" stop-color="#8B8B95"/>
</linearGradient>
<radialGradient id="paint7_radial_8010_175492" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(108.715 107.675) scale(19.2441 19.2441)">
<stop offset="0.6" stop-color="white"/>
<stop offset="0.8" stop-color="#FDFDFD"/>
<stop offset="0.9" stop-color="#EAEBED"/>
<stop offset="1" stop-color="#D4D5DA"/>
</radialGradient>
<radialGradient id="paint8_radial_8010_175492" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(109.045 4.44144) scale(1.45686 1.45686)">
<stop offset="0.3" stop-color="#050506"/>
<stop offset="0.4" stop-color="#080809"/>
<stop offset="0.6" stop-color="#131313"/>
<stop offset="0.7" stop-color="#242425"/>
<stop offset="0.8" stop-color="#3E3E3E"/>
<stop offset="0.9" stop-color="#5E5E5E"/>
<stop offset="1" stop-color="#777777"/>
</radialGradient>
<radialGradient id="paint9_radial_8010_175492" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(109.202 4.61507) rotate(-45) scale(0.677609)">
<stop stop-color="#050506"/>
<stop offset="0.2" stop-color="#07050D"/>
<stop offset="0.4" stop-color="#0D0522"/>
<stop offset="0.7" stop-color="#180643"/>
<stop offset="0.8" stop-color="#1E1254"/>
<stop offset="0.9" stop-color="#252169"/>
<stop offset="1" stop-color="#3B4AA3"/>
</radialGradient>
<radialGradient id="paint10_radial_8010_175492" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(61.7139 20.7036) scale(140.977 140.977)">
<stop stop-color="white"/>
<stop offset="0.4" stop-color="#C0C0C0"/>
<stop offset="1" stop-color="#545454"/>
</radialGradient>
<radialGradient id="paint11_radial_8010_175492" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(108.384 57.7708) scale(90.6641)">
<stop stop-color="white"/>
<stop offset="0.8" stop-color="#FDFDFE"/>
</radialGradient>
</defs>
</svg>
</template>
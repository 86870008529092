<script setup>
const backendStore = useBackendStore();

const carCount = backendStore.carCount.value;

useHead({
  title: "Współpraca dla dealerów - zwiększ sprzedaż samochodów online",
  meta: [
    { name: 'description', content: 'Vehis.pl to platforma oferująca samochody dealerskie z finansowaniem. Skontaktuj się i sprawdź jak możemy zwiększyć sprzedaż Twoich samochodów.' }
  ],
});
</script>

<template>
  <div class="sticky top-[97px] md:top-[111px] z-50">
    <Container no-paddings gray>
      <MenuScrolled>
        <AnchorJump>Platforma vehis.pl</AnchorJump>
        <AnchorJump to="partner">Sprawdzony partner</AnchorJump>
        <AnchorJump to="strengths">Dlaczego warto</AnchorJump>
        <AnchorJump to="how-vash-works">Jak działa VASH?</AnchorJump>
        <AnchorJump to="cooperation">Współpraca</AnchorJump>
      </MenuScrolled>
    </Container>
  </div>

  <Container custom-position="relative" with-border-bottom-rounded="rounded-b-[8px]" no-paddings
    custom-background="bg-unset lg:[background:linear-gradient(180deg,#FFF_0%,#F3F4F6_30.07%,#F3F4F6_70.24%,#FFF_100%)] shadow-[0px_1px_5px_0px_rgba(0,0,0,0.15),0px_2px_4px_0px_rgba(0,0,0,0.06),0px_4px_6px_0px_rgba(0,0,0,0.10)] ounded-[0px_0px_8px_8px]">
    <div class="grid grid-rows-[auto,328px] sm:grid-cols-1 lg:grid-rows-1 xl:grid-cols-2">
      <div class="py-6 max-w-[624px] lg:max-w-[474px] 2xl:max-w-full lg:min-h-[460px]">
        <h1 class="mt-0 2xl:mt-5 mb-6 text-4xl font-extralight leading-10">Platforma oferująca <span class="font-normal">samochody dealerskie z finansowaniem</span></h1>
        <div class="flex flex-col">
          <div class="font-extralight text-lg flex">
            <IconCheckGreen class="!w-6 !h-6 mr-2 mb-2" /> 
            <p><span class="font-normal whitespace-pre"></span> Proste i szybkie finansowanie VEHIS, również w <span class="font-normal whitespace-pre"> 100% online,</span> z decyzją <span class="font-normal whitespace-pre">w 15 minut </span></p>
          </div>
          <div class="font-extralight text-lg flex mb-2">
            <IconCheckGreen class="!w-8 !h-6 mr-2" />
            <p><span class="font-normal whitespace-pre">Sprzedaż online </span> przez platformę vehis.pl i <span class="font-normal whitespace-pre">offline</span> (sieć ponad 100 doradców w 14 oddziałach VEHIS w Polsce) </p>
          </div>
          <div class="font-extralight text-lg flex">
            <IconCheckGreen class="!w-6 !h-6 mr-2" />
            <p><span class="font-normal whitespace-pre">Bezpłatna i zautomatyzowana</span> prezentacja oferty z Twojego stocku</p>
          </div>
        </div>

      </div>
      <div class="relative">
        <img
          class="rounded-lg object-center object-cover md:object-contain w-full lg:object-right 2xl:bg-unset h-[328px] md:h-unset absolute right-0 bottom-0 2xl:h-full  2xl:max-w-[680px]"
          src="assets/images/kongres-dealerow.png" alt="kongres dealerow obrazek" />
      </div>
    </div>
  </Container>

  <Container>
    <LayoutColumns>
      <LayoutMainColumn ref="topPage">

        <div id="partner" class="w-full md:col-span-2 lg:col-span-2 xl:col-span-3 mb-6">
          <MainContentHeader class="pb-12 pt-4">VEHIS, Twój sprawdzony partner w biznesie</MainContentHeader>
            <div class="grid grid-cols-1 sm:grid-cols-2 gap-12">
                <div class="md:max-w-[300px] flex flex-col items-center">
                    <VectorClients class="h-20 mb-6"/>
                    <p class="text-5xl text-azure-dark mb-1">8 000+</p>
                    <p class="text-azure-dark font-light text-xl text-center">zadowolonych klientów</p>
                </div>
                <div class="md:max-w-[300px]  flex flex-col items-center">
                    <VectorCars class="h-20 mb-6"/>
                    <p class="text-5xl text-azure-dark mb-1">{{carCount}}</p>
                    <p class="text-azure-dark font-light text-xl text-center">samochodów dealerskich</p>
                </div>
                <div class="md:max-w-[300px] flex flex-col items-center">
                    <VectorEnterpriseInvestors class="h-[115px] mb-6"/>
                    <p class="text-azure-dark font-light text-xl text-center">Naszym inwestorem strategicznym jest jedna z największych firm private equity w Europie Środkowo-Wschodniej.</p>
                </div>
                <div class="md:max-w-[300px] flex flex-col items-center">
                    <VectorZpl2 class="mb-6 h-[115px]"/>
                    <p class="text-azure-dark font-light text-xl text-center">Jesteśmy członkiem ZPL,
                        największej organizacji reprezentującej branżę leasingową w Polsce.
                    </p>
                </div>
            </div>
        </div>
        <div id="strengths" class="w-full md:col-span-2 lg:col-span-2 xl:col-span-3 mb-6">
            <MainContentHeader class="pb-12 pt-4">Dlaczego warto</MainContentHeader>
            <div class="grid grid-cols-1 sm:grid-cols-2 gap-6">
                <div class="sm:max-w-full w-full md:h-[160px] flex flex-col items-center">
                    <div class="bg-light-gray-gradient flex justify-center items-center w-full mb-6">
                        <VectorCarsInLaptop class="h-40"/>
                    </div>
                    <div class="p-6">
                        <h3 class="w-full pb-4 border-b border-cool-gray font-medium text-black text-2xl mb-3">
                            Bezkosztowe zwiększenie sprzedaży
                        </h3>
                        <p class="text-lg font-extralight">
                            Wyświetlanie samochodów na naszym portalu jest bezpłatne. Cały proces sprzedaży także bierzemy na siebie.
                        </p>
                    </div>
                </div>
                <div class="sm:max-w-full w-full flex flex-col items-center">
                    <div class="bg-light-gray-gradient flex justify-center items-center w-full mb-6">
                       <img src="/assets/images/PolishMap.png" alt="mapa polski">
                    </div>
                    <div class="p-6">
                        <h3 class="w-full pb-4 border-b border-cool-gray font-medium text-black text-2xl mb-3">
                            Ogólnopolski zasięg Twojego salonu
                        </h3>
                        <p class="text-lg font-extralight">
                            Współpraca z VEHIS poszerzy Twój zasięg geograficzny. Twoje oferty udostępnimy klientom platformy online oraz ponad 100 handlowcom z 14 oddziałów VEHIS.
                        </p>
                    </div>
                </div>
                <div class="sm:max-w-full w-full flex flex-col items-center">
                    <div class="bg-light-gray-gradient flex justify-center items-center w-full mb-6">
                        <img src="/assets/images/Ai.jpg" alt="">
                    </div>
                    <div class="p-6">
                        <h3 class="w-full pb-4 border-b border-cool-gray font-medium text-black text-2xl mb-3">
                            Automatycznie generowane oferty
                        </h3>
                        <p class="text-lg font-extralight">
                            Publikacją i aktualizacją ogłoszeń zajmie się wspierany sztuczną inteligencją system VASH. Wystarczy, że udostępnisz nam stock samochodów w dowolnej postaci.
                        </p>
                    </div>
                </div>
                <div class="sm:max-w-full w-full flex flex-col items-center">
                    <div class="bg-light-gray-gradient flex justify-center items-center w-full h-40 mb-6">
                        <VectorValueSlider class="h-10"/>
                    </div>
                    <div class="p-6">
                        <h3 class="w-full pb-4 border-b border-cool-gray font-medium text-black text-2xl mb-3">
                            Pełna kontrola nad ceną
                        </h3>
                        <p class="text-lg font-extralight">
                            System automatycznie przypisuje cenę samochodu wg ustalonego przez Ciebie klucza wyceny dla marki, modelu czy nawet pojedynczej konfiguracji.
                        </p>
                    </div>
                </div>
                <div class="sm:max-w-full w-full flex flex-col items-center">
                    <div class="bg-light-gray-gradient flex justify-center items-center w-full mb-6">
                        <VectorCarWithArrows class="h-40"/>
                    </div>
                    <div class="p-6">
                        <h3 class="w-full pb-4 border-b border-cool-gray font-medium text-black text-2xl mb-3">
                            Jednolity ekosystem sprzedażowy
                        </h3>
                        <p class="text-lg font-extralight">
                            Twoje samochody automatycznie trafią na nasz portal, do reklam, kalkulatorów leasigu i ofert przygotowywanych przez nasze sieci sprzedażowe.
                        </p>
                    </div>
                </div>
                <div class="sm:max-w-full w-full flex flex-col items-center">
                    <div class="bg-light-gray-gradient flex justify-center items-center w-full mb-6">
                        <VectorAdvertCampaign class="h-40"/>
                    </div>
                    <div class="p-6">
                        <h3 class="w-full pb-4 border-b border-cool-gray font-medium text-black text-2xl mb-3">
                            Ogólnopolska kampania reklamowa
                        </h3>
                        <p class="text-lg font-extralight">
                            Zapewniamy bezpłatnie promocję Twoich samochodów w internecie oraz w tradycyjnych mediach o zasięgu ogólnokrajowym.
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <BlockCongressHowTo :id="'how-vash-works'" class="text-center" :hide-subtitle="true" />
        <BlockContactUs id="cooperation" />
        <FormCongressContact :title="'Zostaw kontakt'" class="lg:hidden flex justify-center" docking btnCenter/>
        </LayoutMainColumn>
      <LayoutSideColumn class="hidden lg:block">
        <FormCongressContact/>
      </LayoutSideColumn>
    </LayoutColumns>
  </Container>
</template>